@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  font-family: 'Roboto';
}

.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt2 {
  margin-top: 2px;
}
.ml26{
  margin-left: 26px;
}
.widthP40{width: 40%;}
.widthP20{width: 20%;}
.widthP15{width: 15%;}
.widthP10{width: 10%;}
.p40{padding: 40px;}

.side-image {
  display: table-cell;
  background: url("../public/assets/images/kims.png");
  background-size: 100% 100%;
  width: 62vw;
  height: 100vh;
}

.side-content {
  display: table-cell;
  width: 38vw;
  height: 100vh
}

.side-content-body {
  padding: 42px 96px;
  text-align: center;
}

.text-input {
  margin-bottom: 20px;
}
.flex-center{
  display: flex;
  justify-content: center;
}
.side-image-text {
  padding-left: 10vw;
  padding-right: 10vw;
  color: #ffffff;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}


sidemenu-li-icon {
  height: 40px !important;
  padding-top: 0.6em !important;
  background-color: linear-gradient(to bottom right, #DA4B48, #EB9554) !important;
}

.txt-algn-left {
  text-align: left;
}

.txt-algn-right {
  text-align: right;
}

@media screen and (max-width: 896px) {
  .txt-algn-left {
    text-align: center;
  }

  .txt-algn-right {
    text-align: center;
  }
}
.layoutParent{
  padding-left: 6.2em;
  font-size: 1.3rem;
  background-color: #F7F8FA;
  color: #717982;
  padding-top: 10px;
}
.width300{
  width: 300px;
}
.width630
{
  width: 630px;
}
.colorGreen{
  color: green;
}
.colorRed{
  color: red;
}
.layoutParentP {
  margin-top: 0;
  padding-top: 1.2em;
}
.layoutContent {
  border-radius: 10px;
  box-shadow: 0px 5px 20px #00000029;
  width: 86vw;
  background-color: #fff;
  margin-top: 20px
}
.verticalTop{
  vertical-align: top;
}
.lgoutBtn {
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.sideBarPopUp {
  position: absolute;
  left: 0px;
  top: -20px;
  background-color: #fff;
  padding: 0;
  box-shadow: 0px 5px 20px #00000029;
  border-radius: 8px;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}

.sideBarPopUp ul {
  margin: 0;
  padding-left: 0;
}

.sideBarPopUp ul li {
  list-style-type: none;
  font-size: 14px;
  border-bottom: 1px solid #efefef;
  padding: 6px 20px;
  text-align: left;
  color: #DA4B48;
}

.sideBarPopUp ul li a {
  text-decoration: none;
  color: #DA4B48;
}

.sideBarPopUp ul li:last-child {
  border-bottom: 0;
}

.lgoutBtn:hover .sideBarPopUp {
  left: 44px;
  visibility: visible;
  opacity: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1200;
}
.modalPopupw{
  width: 500px !important;
}
.heightWScroll
{
  max-height: 90vh;
  overflow-y: scroll;
}
.color444
{
  color: #444;
}

.modalPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1201;
  background-color: #fff;
  width: 310px;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 5px 20px #00000029;
}

.modalPopup h4 {
  font-size: 15px;
  margin: 0;
  padding: 20px;
  text-align: center;
  line-height: 22px;
}

.modalPopup button {
  border: 0;
  width: 50%;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.yesRemoveBtn {
  background-color: #DA4B48;
}

.cancelBtn {
  background-color: #EB9554;
}

.btnHandle {
  border: 0;
  color: #fff;
  background-image: linear-gradient(to right bottom, rgb(218, 75, 72), rgb(235, 149, 84));
  padding: 10px 20px;
  width: 92px;
  border-radius: 8px;
  margin-bottom: 3px;
  cursor: pointer;
}

.failurBt {
  border: 1px solid rgb(218, 75, 72);
  color: rgb(218, 75, 72);
  background-image: linear-gradient(to right bottom, rgb(255, 255, 255), rgb(255, 255, 255));
}
.gradientBtn{
  background-image: linear-gradient(to bottom right, #DA4B48, #EB9554);
  border-radius: 15px !important;
  margin-top: 3vh !important;
  padding: 10px 20px !important;
  margin-bottom: 16px !important;
  color: #fff !important;
}
.paymentAdvGrid{
  width: 50vw;
  height: auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.icgridp{
  height: 300px;
  width: 100%;
}
.noDataIC{
  font-style: 16px;
  text-align: center;
  color: #444;
  margin: 10px;
  padding: 20px;
}