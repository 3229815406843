.patientCard
{
    position: relative;
    overflow: hidden;
    background-color: #F4EEEE;
    padding: 30px;
    padding-right: 100px;
    border: 1px solid rgba(224, 224, 224, 1);
}
.profileCard
{
    display: flex;
    background-color: #fff;
    /* padding: 16px 21px; */
    padding: 0px 21px;
    border-radius: 50px;
    margin-top: 0 !important;
}
.profileDetail
{
    margin-left: 6px;
    margin-right: 0;
    margin-top: -3px;
}
.profileDetail h4{
    margin: 0;
    color: #E05B3D;
    white-space: nowrap;
    margin-top: 3px;
    font-size: 14px;
}
.profileDetail .mob{
    margin-top: 3px;
    font-size: 14px;
    color: #000;
    margin-bottom: 0;
}
.profile1{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.profile1 div{
    margin-top: 8px;
    align-items: center;
    margin-right: 10px;
}
.profile1 span{
    font-size: 12px;
}
.profile1 p{
    font-size: 14px;
    color: #000;
    margin-top: 6px;
    margin-bottom: 0;
    white-space: nowrap;
    font-weight: 600;
}
.profileDataGrid{
    height: auto;

    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16px;
    background-color: #fff;
}
.profileDataGrid .MuiDataGrid-columnSeparator
{
    display: none !important;
}
.rad0{
    border-radius: 0;
}
.hideIt{
    display: none !important;
}
.menuListitem{
    margin: 10px 0 !important;
}
.menuListitem div img{
    filter: invert(.45);
}
.menuListitem.active div img{
    filter: invert(0);
}
.menuListitem.active div{
    box-shadow: 0px 7px 12px #da4b485e;
}
.menuListitem div{
    width: 58px;
    height: 58px;
    padding: 16px;
    text-align: center;
    border-radius: 14px;
    margin: 0 auto;
}
.menuListitem.active div{
    background-image: linear-gradient(#EC9655, #CF5F4F);
}
*{
    box-sizing: border-box;
}

.tableWithHadle{
    width: 92%;
    margin: 10px 45px 0px 35px;
    overflow: auto;
}
.data-grid-table, .data-grid-table thead, .data-grid-table th, .data-grid-table body, .data-grid-table td
{   
    border: 1px solid rgba(224, 224, 224, 1);
    border-collapse: collapse;
}
.data-grid-table{
    width: 100%;
}
.data-grid-table th{
    font-size: 14px;
    color: #272727;
    padding: 15px 6px;
    padding-left: 8px;
    text-align: left;
    border: 0;   
    position: relative;
    font-weight: 600;
    background-color: #f1f1f1;
}
/* .data-grid-table tr:hover{
    background-color: #f1f1f1;
} */
.data-grid-table td{
    font-size: 14px;
    padding: 15px 6px;
    color: #000000;
    font-weight: 400;
}
.data-grid-table tbody tr td{
    border: 0;
}
.addCommentBtn
{
    border: 1px solid #BABABA ;
    padding: 6px 14px;
    color: #D36650;
    background-color: #fff;    
    cursor: pointer;
}
.textareaField{
    height: 74px;
    resize: none;
    border: 1px solid #BABABA ;
    background-color: #fff;    
    padding: 6px;
    outline: none;
}
.tabs_list ul
{
    margin: 0;
    text-align: center;
    padding-left: 0;
}
.tabs_list ul li{
    list-style-type: none;
    font-size: 16px;
    display: inline-block;
    padding: 0 20px;
    cursor: pointer;
}
.tabs_list ul li.activeTab{
    border-bottom: 3px solid #D97151;
    padding-bottom: 8px;
    color: #D97151;
}
.filterBtnCover
{
    padding-left: 40px;
    padding-right: 40px;
}
.filterBtnCover button{
    background-color: #fff;
    border: 1px solid #272727;
    color: #272727;
    height: 28px;
    width: 80px;
    font-size: 14px;
    cursor: pointer;
}
.filterBtnCover button img{ 
    vertical-align: sub;
}
.FiltersList 
{
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.FiltersList label{
    font-size: 14px;
}
.FiltersList input, .FiltersList select{
    width: 170px;
    height: 32px;
    margin-left: 7px;
}
.FiltersList div{
    width: auto;
    flex: 1;
}
.applyFilterDiv{
    margin-top: 0px;
}
.applyFilterDiv button{
    margin: 0 2px;
    color: #fff;
    border: 0;
    padding: 0 12px;
    border-radius: 4px;
    cursor: pointer;
    height: 56px;
    margin-top: 8px;
    width: 108px;
    font-size: 14px;
}
.applyFilterDiv .applyBtn{
    background-color: #EC9655;
}
.applyFilterDiv .resetBtn{
    background-color: #CF5F4F;
}
.applyFilterDiv .closeBtn{
    background-color: #787170;
}
.viewBtn
{
    font-size: 13px;
    color: #D36650;
    cursor: pointer;
}
.editAmount
{
    display: flex;
}
.editAmount input
{
    width: 110px;
    padding-left: 6px;
    outline: none;
    margin-bottom: 4px;
    display: block;
    border: 1px solid #787170;
    border-radius: 4px;
}
.editAmount textarea{
    width: 110px;
    height: 50px;
    padding: 6px;
    resize: none;
    outline: none;
    border: 1px solid #787170;
    border-radius: 4px;
}
.editAmountBtn{
    margin-left: 2px;
}
.modifyAmtBtn, .tableApproveBtn
{
    border: 0;
    background-image: linear-gradient(#EC9655, #CF5F4F);
    border-radius: 4px;
    color: #fff;
    padding: 6px 10px;
    cursor: pointer;
}

.editAmountBtn button{
    height: 35px;
    margin-bottom: 3px;
    border: 0;
    cursor: pointer;
    display: block;
    border-radius: 4px;
}
.editAmountBtn button svg{
font-size: 16px;}
.editAmountBtn button:first-child{
    background-color: #EC9655;
    color: #fff;
}
.editAmountBtn button:last-child{
    background-color: #787170;
    color: #fff;
}
.modfiedTextAmt
{
    margin: 0;
}
.modfiedTextAmt .diff{
    border: 1px solid #EC9655;
    padding: 6px 10px;
    padding-right: 24px;
    font-size: 13px;
    border-radius: 4px;
    background-color: #f1f1f1;
    color: #EC9655;
    font-weight: 600;
    position: relative;
}
.descToolTip{
    display: none;
}
.diff:hover .descToolTip{
    display: block;
    position: absolute;
    color: #222;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    width: 140px;
    bottom: 30px;
    padding: 6px;
    border-radius: 8px;
}
.iconInfo
{
    position: absolute;
    font-size: 16px !important;
    right: 2px;
    cursor: pointer;
}
.modalPopup .comments_view_modal:last-child{
    border-bottom: 0;
} 
.comments_view_modal
{
    padding: 5px 15px;
    border-bottom: 1px solid #c8c8c8;
}
.comments_view_modal p
{
    font-size: 14px;
    color: #222;
    margin: 0;
}
.comments_view_modal span
{
    font-size: 13px;
    color: #787170;
}
.modalPopup .closeBtn{
    width: 100%;
    background-image: linear-gradient(#EC9655, #CF5F4F);
    padding: 6px;
    font-size: 14px;
    margin-top: 12px;
}
.modalPopup h3{
    font-size: 16px;
    color: #272727;
    text-align: center;
}
.modalPopup .handleHeight{
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
}
.disableAprove
{
    opacity: 0.45;
    pointer-events: none;
    cursor: not-allowed;
}
.viewBtnModal
{
    color: #EC9655;
    font-size: 14px;
    cursor: pointer;
}
.modalViewPopup
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 1200;   
}
.approvalComment{
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    width: 342px;
    margin-top: 27px;
    margin-bottom: 20px;
}
.approvalComment label
{
    width: 160px;
    display: inline-block;
    font-size: 14px;
    color: #272727;
}
.approvalComment input{
    width: 180px;
    margin-bottom: 12px;
    height: 32px;
    padding-left: 7px;
    outline: none;
    border:1px solid #BABABA;
    border-radius:8px
}
.approvalComment textarea{
    width: 180px;
    height: 68px;
    resize: none;
    margin-bottom: 12px;
    padding-top: 4px;
    padding-left: 7px;
    outline: none;
    border:1px solid #BABABA;
    border-radius:8px
}
.approvalComment button{
    background-image: linear-gradient(#EC9655, #CF5F4F);
    border-radius: 8px;
}
.amountView p{
    width: 50%;
    display: inline-block;
    font-size: 14px;
    color: #272727;
    text-align: center;
}
.amountView p span{
    font-weight: 600;
}
.amountView
{
    border-bottom: 1px solid #BABABA;
}
.customPading tr td:last-child{
    padding-right: 20px;
}
.autoComplete{
    margin-top: 8px;
    width: 100%;
}
.autoComplete .MuiFormControl-fullWidth{
    width: 100%;
}
.autoComplete input
{
    height: auto;
    width: 100%;
}

.filterBox{
    background-color: #f1f1f1;
    padding: 20px 0;
    margin-top: 10px;
}

.edit-comment {
    width: 90%;
    margin-top: 2em;
}

.disabledTextField {
    color: #999;
    background-color: #f4f4f4;
}

.comments_table_block {
    width: 90%;
    margin: 20px;
    overflow: auto;
    padding-left: 15px;
}

.comment_createdat {
    color : #004C91;
    width: 100%;
    font-weight: bold;
    display: block;
    line-height: 5em;
}

.comment_row {
    border-bottom: 1px solid #999;
    padding-bottom: 15px;
}