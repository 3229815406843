.conflicts_tabs
{
    border-bottom: 1px solid #BABABA;
    margin-top: 20px;
    padding-top: 12px;
    margin-bottom: 20px;
}
.conflicts_tabs ul{
    padding-left: 0;
    margin: 0;
    display: inline-block;
}
.conflicts_tabs ul li{
    list-style-type: none;
    display: inline-block;
    font-size: 14px;
    border-right: 0;
    padding: 8px 18px;
    cursor: pointer;
    color: #969696;
}

.activeTab
{
    color: #CF5F4F !important;
    border-bottom: 2px solid #D97151;
}
.tableCardHeader
{
    display: flex;
    padding: 0 26px;
}
.tableCardHeader p
{
    font-size: 14px;
    font-weight: 600;
    color: #222;
}
.customCardStyleHandler .MuiAccordionSummary-expandIconWrapper
{
    order:-1
}
.conflictsDiv
{
    padding-left: 6.2em;
    font-size: 1.3rem;
    background-color: #F7F8FA;
    color: #717982;
    padding-top: 10px;
}

.referred_options_block {
    float: right;
    margin-right: 30px;
    display: flex
}

.visit_type_block {
    float: left;
}

.visit_type_change {
    width: 60px;
    height: 30px;
    border: 1px solid #272727;
    /* box-shadow: 0px 4px 4px 0px #00000080; */
}

.filter_block {
    display: inline-block;
    margin-left: 15px;
    /* box-shadow: 0px 4px 4px 0px #00000080; */
    border: 2px solid #272727;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
}

.filterBtn {
    background-color: #F7F8FA;
}

.filterIcon {
    position: relative;
    top: 2px;
}

.filter_options_container {
    margin-left: 35px;
}

.filter_label {
    color : #272727;
    font-size: 12px;
    position: relative;
    bottom: 3px;
    padding: 5px;
}

.filter_referrer_block, .filter_patient_block, .filter_status_block {
    display: inline;
    margin-right: 15px;
}

.filter_status_block {
    position: relative;
    top: 7px;
}

.filter_status_buttons_block {
    display: inline;
    margin-right: 15px;
    position: relative;
    top: 9px;
}

.filter_referrer_label, .filter_patient_label, .filter_status_label {
    color: #333333;
    font-size: 13px;
    display: inline-block;
    margin: 20px 10px 0px 0px;
}

.applied_filter_item {
    padding-inline-start: 1rem;
    margin: 0 .75rem .75rem 0;
    font-size: .75rem;
    line-height: 1.3333333333;
    min-height: 2.5rem;
    padding: 0 1.5rem;
    font-weight: bold;
    text-align: center;
    display: inline-flex;
    border-radius: 64px;
    cursor: pointer;
    outline: 0;
    vertical-align: top;
    color: #111111;
    background: #F5F5F5;
    justify-content: center;
    align-items: center;
}

.applied_filter_label {
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    font-size: .75rem;
    padding-left: 0.5rem;
}

.close_svg_icon{
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
}

em {
    color: #ACACAC;
}

.add_comment_button {
    border: 1px solid #BABABA;
    color: #D36650;
    font-size: 12px;
    height: 35px;
    text-align: center;
    padding-top: 10px;
    width: 60%;
    cursor: pointer;
}


  .popup-menu-container {
    position: relative;
  }
  .popup-menu {
    position: absolute;
    z-index: 2;
    transform: scale(0);
    transform-origin: top left;
    width: 80%;
    background: #fff;
    transition: transform 0.2s;
  }
  .popup-menu.shown {
    transform: scale(1);
  }

.MuiDialog-paperWidthSm {
    width : 35%;
}

.MuiButtonBase-root:hover {
    background: white !important
}

.MuiTouchRipple-root {
    display: none !important;
}

.MuiTextField-root {
    margin-top: 0px !important;
}
